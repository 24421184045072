@font-face {
  font-family: LustTextMedium;
  src: url("Lust-Text-Medium.f5e991ec.otf");
}

@font-face {
  font-family: LustTextItalic;
  src: url("Lust-Text-Italic.2b668de5.otf");
}
/*# sourceMappingURL=index.58c2ec73.css.map */
